<template>
    <v-flex fill-height>
        <v-layout justify-space-between pa-2 row>
            <v-flex v-t="'sa.support.actions.delete-node-surveys.title'" headline shrink />
        </v-layout>
		<v-layout fill-height pa-4>
            <v-flex>
                <v-layout column justify-center>
                    <v-flex my-3 shrink>
                        <v-layout column>
                            <w-text-input
v-model="userEmail" auto-focus
					:label="$t('sa.support.actions.delete-node-surveys.user-email')" maxlength="191"
					type="email" required></w-text-input>
                            </v-layout>
                    </v-flex>
                    <v-flex my-3 shrink>
                        <WButton :disabled="!userEmail" @click="execute">{{ $t('actions.launch') }}</WButton>
                    </v-flex>
                </v-layout>
            </v-flex>
        </v-layout>
    </v-flex>
</template>

<script>
import SuperAdminModuleGuard from '@/mixins/ModulesGuards/SuperAdmin/SuperAdminModuleGuard'
export default {
    name: 'DeleteNodeSurveys',
    mixins: [SuperAdminModuleGuard],
    data: function () {
		return {
            userEmail: null,
        }
    },
    methods: {
        execute: function () {
            return this.service.executeCommand({ 
                    command: "support:node-surveys:delete", 
                    params: {
                        'user-email': this.userEmail
                    }
            }).then(() => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_SUCCESS, this.$t('sa.support.success'))
            }).catch(error => {
                this.appEventBus.emit(this.appEvents.SNACKBAR_ERROR, error)
            })
        }
    }
}
</script>